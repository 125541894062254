import {
    SET_PLOTS,
    SET_CURRENT_PLOT,
    OPEN_PLOT_MODAL,
    CLOSE_PLOT_MODAL,
    RESET_FILTER,
    SET_FILTER,
    ADD_PLOTS,
} from '../mutation-types'

const img = {
    title: "",
    description: "",
}

const state = {
    plots: [],
    backup: [],
    currentPlot: {
        id: null,
        image: img,
        seller: "",
        type: "",
        name: "",
        width: "",
        area: "",
        world: "",
        sale_id: "",
        description: "",
        images: [img],
    },
    plotModal: false,
    filtered: 0
}

const getters = {
    plots: state => state.plots,
    currentPlot: state => state.currentPlot,
    isPlotModalActive: state => state.plotModal
}

const actions = {
    addPlots({commit}, plots) {
        commit(ADD_PLOTS, plots)
    },
    setPlots({commit}, plots) {
        commit(SET_PLOTS, plots)
    },
    setCurrentPlot({commit}, plot) {
        commit(SET_CURRENT_PLOT, plot)
    },
    openPlotModal({commit}) {
        commit(OPEN_PLOT_MODAL)
    },
    closePlotModal({commit}) {
        commit(CLOSE_PLOT_MODAL)
    },
    filterBy({commit}, filters) {
        commit(SET_FILTER, filters)
    },
    resetPlots({commit}) {
        commit(RESET_FILTER)
    }
}

const mutations = {
    [SET_PLOTS](state, plots) {
        state.backup = plots
        state.plots = state.backup
    },
    [ADD_PLOTS](state, plots) {
        state.backup = state.backup.concat(plots)
        state.plots = state.backup
    },
    [SET_CURRENT_PLOT](state, plot) {
        state.currentPlot = state.plots.find(innerPlot => innerPlot.asset_id === plot.asset_id)
        state.currentPlot.images = plot.images
        state.currentPlot.desctipion = plot.description
    },
    [OPEN_PLOT_MODAL](state) {
        state.plotModal = true
    },
    [CLOSE_PLOT_MODAL](state) {
        state.plotModal = false
    },
    [SET_FILTER](state, filters) {
        if (state.filtered === 0) {
             state.backup = state.plots
        }
        state.filtered++
        const filterKeys = Object.keys(filters)

        state.plots = state.backup
        state.plots = state.plots.filter((plot) => {
            return filterKeys.every(key => {
                if (typeof filters[key] !== 'function') {
                    return false
                }

                return filters[key](plot)
            })
        })
    },
    [RESET_FILTER](state) {
        state.plots = state.backup
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
