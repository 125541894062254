var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.images.length > 0
    ? _c(
        "div",
        { staticClass: "lightbox-wrapper" },
        [
          _c("LightBox", {
            ref: "lightbox",
            staticClass: "lightbox",
            attrs: { media: _vm.images, "show-light-box": false },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "img-container bg-dark" }, [
            _c(
              "div",
              {
                staticClass: "image-slider",
                style: {
                  width: _vm.sliderWidth + "px",
                  height: _vm.height + "px",
                },
                attrs: { id: "image-slider" },
              },
              _vm._l(_vm.images, function (img, index) {
                return _c("img", {
                  key: index,
                  staticClass: "img img-fluid",
                  style: {
                    height: _vm.height + "px",
                  },
                  attrs: { src: img.url, alt: img.title, id: "image-" + index },
                  on: {
                    click: function ($event) {
                      return _vm.openLightBox(index)
                    },
                  },
                })
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "previous", on: { click: _vm.slidePrevious } },
              [_c("i", { staticClass: "fa fa-chevron-left remove-btn" })]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "next", on: { click: _vm.slideNext } }, [
              _c("i", { staticClass: "fa fa-chevron-right remove-btn" }),
            ]),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }