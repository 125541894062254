import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user.js'
import Plots from './modules/plots.js'
import Wax from './modules/wax.js'
// import Wallets from './modules/wallets.js'
import Pagination from './modules/pagination.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        Wax,
        // Wallets,
        User,
        Plots,
        Pagination,
    },
})
