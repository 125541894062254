export const SET_PAGINATION = 'SET_PAGINATION'

export const ADD_PLOTS = 'ADD_PLOTS'
export const SET_PLOTS = 'SET_PLOTS'
export const SET_CURRENT_PLOT = 'SET_CURRENT_PLOT'

export const SET_WALLETS = 'SET_WALLETS'
export const ADD_WALLET = 'ADD_WALLET'
export const OPEN_PLOT_MODAL = 'OPEN_PLOT_MODAL'
export const CLOSE_PLOT_MODAL = 'CLOSE_PLOT_MODAL'

export const SET_USER = 'SET_USER'
export const SET_FILTER = 'SET_FILTER'
export const RESET_FILTER = 'RESET_FILTER'

export const SET_CURRENT_WAX_WALLET = 'SET_CURRENT_WAX_WALLET'
export const SET_CURRENT_WALLET_ADDRESS = 'SET_CURRENT_WALLET_ADDRESS'
export const OPEN_WALLET_MODAL = 'OPEN_WALLET_MODAL'
export const CLOSE_WALLET_MODAL = 'CLOSE_WALLET_MODAL'

export const SET_WAX = 'SET_WAX'
export const SET_WAX_WALLET = 'SET_WAX_WALLET'
export const LOGIN = 'LOGIN'
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL'
export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL'
export const SET_WAX_USER = 'SET_WAX_USER'
export const SET_WAX_RATE = 'SET_WAX_RATE'


