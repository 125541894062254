 import {
    SET_USER
} from '../mutation-types'

const state = {
    user: {
        name: null,
        allowed_images: 0,
    },
}

const getters = {
    user: state => state.user,
}

const actions = {
    setUser({commit}, user) {
        commit(SET_USER, user)
    },
}

const mutations = {
    [SET_USER](state, user) {
        state.user = user
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
