import {sweetAlert} from "./utilities/sweet-alert";

require('./bootstrap')
import 'firebase/auth'
import Vue from 'vue'
import 'core-js/stable'
import './../scss/app.scss'
import {JsonRpc} from "eosjs"
import * as ualWax from "@eosdacio/ual-wax"
import {Anchor} from 'ual-anchor'
import VueLazyLoad from 'vue-lazyload'
import store from './store/index.js'
import {UALJs} from "ual-plainjs-renderer"
import Vue2TouchEvents from 'vue2-touch-events'
import ImageSlider from './components/utilities/ImageSlider.vue'
import Vuex, {mapActions, mapGetters} from 'vuex'

Vue.use(Vuex)
Vue.use(Vue2TouchEvents)
Vue.use(VueLazyLoad)

const app = new Vue({
    el: '#app',
    data: {
        isOverlayVisible: false,
        menu: {
            active: window.innerWidth > 991
        },
        userBalance: 0,
        balanceInterval: null
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 991
        },
        ...mapGetters({
            wax: 'wax',
            user: 'waxUser',
            wallet: 'wallet',
            waxRate: 'waxRate',
            waxUser: 'waxUser',
            currentPlot: 'currentPlot',
        })
    },
    store,
    mounted() {
        this.initWAX()
        this.setWaxRate().then(r => console.log(r))
    },
    methods: {
        goToURL(url) {
            window.location.href = url
        },
        toggleMenu() {
            this.menu.active = !this.menu.active
            this.isOverlayVisible = this.menu.active
        },
        initWAX() {
            let container = document.getElementById('ual-div')
            if (!container || typeof container === 'undefined') {
                return
            }

            let chain = {
                chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
                rpcEndpoints: [
                    {
                        host: 'wax.greymass.com',
                        protocol: 'https',
                        port: '443',
                    }
                ]
            }

            const ual = new UALJs(
                this.userCallback,
                [chain],
                'LAX Virtual Land',
                [
                    new ualWax.Wax([chain]),
                    new Anchor([chain], {
                        appName: 'laxvirtualan',
                    }),
                    // new Scatter([chain], {appName: 'laxvirtualan'}),
                    // new Wombat([chain], { appName: 'laxvirtualan' })
                ],
                {
                    containerElement: container
                }
            )
            ual.init()
            let button = container.querySelector('#ual-button')
            button.innerHTML = 'WAX Login'
            button.style.backgroundColor = '#f06d85';
            button.style.color = '#fff';
            button.style.border = '0';
            this.setCurrentWaxObject(ual)
        },
        logout() {
            axios.defaults.headers.post['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            axios.post('/logout').then(({data}) => {
                clearInterval(this.balanceUpdateInterval)
                this.wax.logoutUser()
            }).then(() => {
                setTimeout(() => {
                    window.location.reload()
                }, 500)
            })
        },
        async userCallback(users) {
            const loggedInUser = users[0]
            const userAccount = await loggedInUser.getAccountName()
            axios.post('/login', {
                wax_wallet: userAccount
            }).then(() => {
                this.setCurrentWallet(userAccount)
                this.setWaxUser(loggedInUser)
                this.updateBalance()
                this.balanceUpdateInterval = setInterval(this.updateBalance, 30000)
            })
        },
        async updateBalance() {
            let host = 'wax.greymass.com'
            let protocol = 'https'
            let port = '443'

            try {
                const rpc = new JsonRpc(`${protocol}://${host}:${port}`)
                const accountName = await this.user.getAccountName()
                const data = await rpc.get_account(accountName)

                const {core_liquid_balance: balance} = data
                this.userBalance = balance

            } catch (e) {
                console.error(e)
                this.userBalance = 0
            }
        },
        onFileChanged(event, plot) {
            console.log(event, plot)
            if (plot.images.length >= 5) {
                this.chargeWAX().then(() => {
                    this.uploadImage(event, plot)
                })
            } else {
                this.uploadImage(event, plot)
            }
        },
        uploadImage(event, plot) {
            let file = event.target.files[0]
            let formData = new FormData()
            formData.append('plot_name', plot.name)
            formData.append('file', file)
            formData.append('wax_address', this.wallet)
            formData.append('seller', plot.owner)
            formData.append('world', plot.world)
            formData.append('width', plot.width)
            formData.append('location', plot.location)
            formData.append('type', plot.type.toLowerCase().replace(' ', '').replace('key', ''))
            this.loading = true

            axios.post(`/api/v1/owned-plots/${plot.asset_id}/images`, formData).then(({data}) => {
                this.setCurrentPlot(data.profile_plot)
                this.showTitle = false
                this.image.title = ''
                this.image.alt = ''
                document.getElementById('image-upload').value = ''
                this.loading = false
            }).catch((error) => {
                console.log(error.response)
                this.loading = false
            })
        },
        async chargeWAX() {
            const user = await this.waxUser.getAccountName()

            try {
                return await this.waxUser.signTransaction({
                    actions: [{
                        account: 'eosio.token',
                        name: 'transfer',
                        authorization: [{
                            actor: user,
                            permission: 'active',
                        }],
                        data: {
                            from: user,
                            to: '2nr.m.c.wam',
                            quantity: '1.00000000 WAX',
                            memo: 'Add plot photo to LAX Virtual Land',
                        },
                    }]
                }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                })

            } catch (e) {
                // console.log(e, typeof e, e.response)
                if (typeof e.json !== 'undefined' && typeof e.json.error.details[0].message !== 'undefined') {
                    sweetAlert.fire(
                        'Error',
                        JSON.stringify(e.json.error.details[0].message),
                        'error'
                    )
                } else if (typeof e === "string") {
                    sweetAlert.fire(
                        'Error',
                        e,
                        'error'
                    )
                } else {
                    sweetAlert.fire(
                        'Error',
                        'Unable to complete transaction',
                        'error'
                    )
                }
            }
        },
        async purchasePlot(plot) {
            console.log(plot)
            const user = await this.waxUser.getAccountName()

            try {
                await this.waxUser.signTransaction({
                    actions: [
                        {
                            account: "atomicmarket",
                            name: "assertsale",
                            authorization: [
                                {
                                    actor: user,
                                    permission: 'active',
                                }
                            ],
                            data: {
                                sale_id: plot.sale_id,
                                asset_ids_to_assert: [
                                    plot.asset_id
                                ],
                                listing_price_to_assert: Number(plot.price).toFixed(8) + ' WAX',
                                settlement_symbol_to_assert: "8,WAX"
                            }
                        },
                        {
                            account: 'eosio.token',
                            name: 'transfer',
                            authorization: [{
                                actor: user,
                                permission: 'active',
                            }],
                            data: {
                                from: user,
                                to: 'atomicmarket',
                                quantity: Number(plot.price).toFixed(8) + ' WAX',
                                memo: 'deposit',
                            },
                        },
                        {
                            account: "atomicmarket",
                            name: "purchasesale",
                            authorization: [{
                                actor: user,
                                permission: 'active',
                            }],
                            data: {
                                buyer: user,
                                sale_id: plot.sale_id,
                                intended_delphi_median: 0,
                                taker_marketplace: "laxvirtualan"
                            }
                        }]
                }, {
                    blocksBehind: 3,
                    expireSeconds: 1200,
                }).then(() => {
                    sweetAlert.fire('Success', 'Congratulations on you new plot!').then(() => {
                        window.location.href = '/dashboard?world=' + plot.world
                    })
                })

            } catch (e) {
                // console.log(e, typeof e, e.response)
                if (typeof e.json !== 'undefined' && typeof e.json.error.details[0].message !== 'undefined') {
                    sweetAlert.fire(
                        'Error',
                        JSON.stringify(e.json.error.details[0].message),
                        'error'
                    )
                } else if (typeof e === "string") {
                    sweetAlert.fire(
                        'Error',
                        e,
                        'error'
                    )
                } else {
                    sweetAlert.fire(
                        'Error',
                        'Unable to complete transaction',
                        'error'
                    )
                }
            }
        },
        ...mapActions({
            setWaxUser: 'setWaxUser',
            setCurrentWallet: 'setCurrentWallet',
            setCurrentWaxObject: 'setCurrentWaxObject',
            openLoginModal: 'openLoginModal',
            setWaxRate: 'setWaxRate',
        })
    },
    components: {
        'minecraft-map': (() => import('./components/MinecraftMap.vue')),
        'plots': (() => import('./components/Plots.vue')),
        'burger': (() => import('./components/utilities/Burger')),
        'dashboard': (() => import('./components/admin/Dashboard.vue')),
        'donate-button': (() => import('./components/utilities/DonateButton')),
        'loading-overlay': (() => import('./components/utilities/LoadingOverlay')),
        'image-slider': ImageSlider,
        'draggable': () => import('vuedraggable'),
    },
})
