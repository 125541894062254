import {
    CLOSE_LOGIN_MODAL,
    OPEN_LOGIN_MODAL,
    SET_WAX,
    SET_WAX_WALLET,
    SET_WAX_USER,
    SET_WAX_RATE,
} from '../mutation-types'

const state = {
    rate: null,
    wax: {},
    loginModal: false,
    wallet: '',
    user: {
        accountName: null
    }
}

const getters = {
    waxRate: state => state.rate,
    wax: state => state.wax,
    wallet: state => state.wallet,
    waxUser: state => state.user,
    isLoginModalActive: state => state.loginModal
}

const actions = {
    setWaxUser({commit}, user) {
        commit(SET_WAX_USER, user)
    },
    setCurrentWallet({commit}, wallet) {
        commit(SET_WAX_WALLET, wallet)
    },
    setCurrentWaxObject({commit}, wax) {
        commit(SET_WAX, wax)
    },
    openLoginModal({commit}) {
        commit(OPEN_LOGIN_MODAL)
    },
    closeLoginModal({commit}) {
        commit(CLOSE_LOGIN_MODAL)
    },
    async setWaxRate({commit}) {
        let {data} = await axios.get('/api/v1/wax-rate')
        commit(SET_WAX_RATE, data.rate)
    },
}

const mutations = {
    [SET_WAX_WALLET](state, wallet) {
        state.wallet = wallet
    },
    [SET_WAX](state, wax) {
        state.wax = wax
    },
    [OPEN_LOGIN_MODAL](state) {
        state.loginModal = true
    },
    [CLOSE_LOGIN_MODAL](state) {
        state.loginModal = false
    },
    [SET_WAX_USER](state, user) {
        state.user = user
    },
    [SET_WAX_RATE](state, rate) {
        state.rate = rate
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
