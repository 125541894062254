import {
    SET_PAGINATION
} from '../mutation-types'

const state = {
    pagination: {
        current: 1,
        totalPages: [1],
        perPage: 10,
        total: 10,
    },
}

const getters = {
    pagination: state => state.pagination,
}

const actions = {
    setPagination ({commit}, paginationData) {
        commit(SET_PAGINATION, paginationData)
    },
}

const mutations = {
    [SET_PAGINATION] (state, data) {
        state.pagination.current = data.current_page
        state.pagination.totalPages = Array(data.last_page).fill().map((_, i) => i + 1)
        state.pagination.total = data.total
        state.pagination.perPage = data.per_page
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
